body {
  background-color: #1e1e1e;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center align items vertically */
  margin: 20px;
  background-color: rgba(83, 83, 83, 0.459);
  box-shadow: 0 8px 32px 0 rgba(31, 31, 31, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

nav h1 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 10px #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 50px;
  transition: 0.5s;
  transition-delay: 0.2s;
  cursor: pointer;
  user-select: none;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  border-right: 1px solid #bbb;
}

nav a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

nav a:hover {
  background-color: #3a3939;
}

nav img {
  width: 50px;
  height: 50px;
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  nav {
    flex-direction: column;
  }

  nav h1 {
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 10px;
  }

  nav ul {
    justify-content: center;
    flex-wrap: wrap;
  }

  nav li {
    border-right: none;
    border-bottom: 1px solid #bbb;
    width: 100%;
  }
}
